
// Some js

'use strict';

var _win = $(window);

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};
var $simpleSlider;
var $hoverSlider;
var $swipeSlider;
var $verticalScrollSlider;
var $threeItemSlider;
var $boxTabs;

function preventDefault(e) {
    e.preventDefault();
  }
  
  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }
// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
    $('body').addClass('bodyBlock');
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
   
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    // $.scrollify.disable();
    return;
}

// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
   
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    $('body').removeClass('bodyBlock');
    // $.scrollify.update();
    // $.scrollify.enable();
}


$(document).on('show.bs.modal','.modal', function () {
    disableScroll();
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  });

  $(document).on('hide.bs.modal','.modal', function () {
    enableScroll();
  });


  

$(_win).on('load',function() {
    
$(window).scroll(function(){
    
    if ($(this).scrollTop() > 100) {
        $('body').addClass('first-scroll');
        // $.scrollify.update();
    } 
     else {
        $('body').removeClass('first-scroll');
    }
});  


inView.offset(70);
inView('.box-img-text') 
    .on('enter', el => {
        el.style.opacity = 1; 
    })
    .on('exit', el => {
        el.style.opacity = 0; 
    });
    
});

$.fn.is_on_screen = function(){
    var win = $(window);
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right ||  viewport.bottom < bounds.top || viewport.top > bounds.bottom));
 };


$(document).ready(function(){
    const selectHotspot = (e) => {
        const clickedHotspot = e.target.parentElement;
        const container = clickedHotspot.parentElement;
        
        // only include hotspots within same image to allow one open hotspot per image; change "container" to "document" to allow only one open hotspot for entire page:
        const hotspots = container.querySelectorAll(".lg-hotspot"); 
        hotspots.forEach(hotspot => {
        if (hotspot === clickedHotspot) {
            hotspot.classList.toggle("lg-hotspot--selected");
        } else {
            hotspot.classList.remove("lg-hotspot--selected");
        }
        });
    }
    (() => {
        const buttons = document.querySelectorAll(".lg-hotspot__button");
        buttons.forEach(button => {
        button.addEventListener("click", selectHotspot);
        });
    })();


    
    $('.bound-fluid-left .card-slider:not(.top) .slider-card').each(function(){
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                appendArrows: $(this),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-fluid-right .card-slider:not(.top) .slider-card').each(function(){
        
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                rtl: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        rtl: false,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-fluid-left .card-slider.top .slider-card').each(function(){
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                appendArrows: $(this),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-fluid-right .card-slider.top .slider-card').each(function(){
        
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                rtl: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        rtl: false,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-fluid-left > .slider-card').each(function(){
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                appendArrows: $(this),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-fluid-right > .slider-card').each(function(){
        
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                rtl: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        rtl: false,
                        }
                    },
                ]
            });
        }
    
    });

    $('.bound-centered .slider-card').each(function(){
        
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 3, 
                slidesToScroll: 1,
                centerMode: false,
                rtl: false,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        rtl: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        rtl: false,
                        }
                    },
                ]
            });
        }
    
    });

    $('.big-slider').each(function(){
        
        if (!$(this).hasClass('slick-initialized')){
            $(this).slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                
            });
        }
    
    });

    $('.hamburger label').on('click', function(){
        $('.menu-lang').toggleClass('open');
    });
 
});


var $firstSection;

var firstSection = function() { 
    $firstSection = $('.section')[0];
    if ($('.section').length) {
        $firstSection.setAttribute('id','section-1');
    } 
}

// Get the button:
let mybutton = document.getElementById("btn-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "flex";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function menuLang() {
    var $toggleBtn =  $("#btn-toggle");
    $toggleBtn.on('click', function(e){
        $('.lang-menu').toggleClass("open"); 
        e.preventDefault();
    })
}

function scrollClass(){

    var timer, el = $('body'),
        flag = false;
    $(window).scroll(function() {
        var theta = document.documentElement.scrollTop / 50 % Math.PI;
        var scrolVal = $(this).scrollTop();
        const {scrollTop, scrollHeight, clientHeight} = document.documentElement;
        const finalPercentage = Math.round((scrollTop + clientHeight) / scrollHeight * 100);
        var multiplier = 0.7;

        // if (finalPercentage < 25 || finalPercentage > 50){
        //     $('.page-bg.coffee-bg .coffee-before').css({"transform": "translate("+theta/10 +"% , " + theta/10 +"%) rotate(" + theta/50 + "rad)" , "transition-duration": "5s", "-webkit-transition-duration": "5s" });
        //     $('.page-bg.coffee-bg .coffee-after').css({"transform": "translate(-"+theta/10 +"% , -" + theta/10 +"%) rotate(-" + theta/50 + "rad)" ,  "transition-duration": "5s", "-webkit-transition-duration": "5s" });
        // } else {
        //     $('.page-bg.coffee-bg .coffee-before').css({"transform": "translate("+theta/10 +"% , -" + theta/10 +"%) rotate(-" + theta/50 + "rad)", "transition-duration": "5s", "-webkit-transition-duration": "5s" });
        //     $('.page-bg.coffee-bg .coffee-after').css({"transform": "translate(-"+theta/10 +"% , " + theta/10 +"%) rotate(" + theta/50 + "rad)", "transition-duration": "5s", "-webkit-transition-duration": "5s"});
        // }
       
            $('.page-bg.coffee-bg .coffee-before').css({"transform": "translate("+document.scrollingElement.scrollTop/100 * 0.7 +"% , " + document.scrollingElement.scrollTop/100 * 0.7   +"%) rotate("+theta+"deg)"});
            $('.page-bg.coffee-bg .coffee-after').css({"transform": "translate(-"+document.scrollingElement.scrollTop/100 * 0.7   +"% , -"+document.scrollingElement.scrollTop/100 * 0.7   +"%) rotate("+theta+"deg)"});
       
 
        if (!flag) { 
            flag = true;
            el.addClass('scrolling');
        }
        clearTimeout(timer);
        timer = setTimeout(function() {
            el.removeClass('scrolling'); 
            flag = false;
        }, 600);
    });

};



$(_win).on('load',function() {
    firstSection();
    menuLang();
    scrollClass();
});
